<template>
  <div class="recognition-page-three">
    <RecognitionPage :backgroundImage="bgImg" :wordObj="wordDetail" :isTonePage='true' :pySize="`xlarge`" />
  </div>
</template>

<script>
import RecognitionPage from "@/components/Course/CoursePage/RecognitionPage";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/14-Pinyin/chapter-01/pinyin-a-tones.svg"),
      wordDetail: {
        toneContent:['ā','á','ǎ','à'],
        songUrlList:[require("@/assets/audio/L1/2-Pinyin-tones/a1.mp3"),
        require("@/assets/audio/L1/2-Pinyin-tones/a2.mp3"),
        require("@/assets/audio/L1/2-Pinyin-tones/a3.mp3"),
        require("@/assets/audio/L1/2-Pinyin-tones/a4.mp3"),] ,
      },
    };
  },
  components: {
    RecognitionPage,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-three {
  width: 100%;
  height: 100%;
}
</style>