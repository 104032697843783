<template>
  <div class="recognition-page-three">
    <RecognitionPage
      :backgroundImage="bgImg"
      :wordObj="wordDetail"
      :isTonePage="true"
      :pySize="`xlarge`"
    />
  </div>
</template>

<script>
import RecognitionPage from "@/components/Course/CoursePage/RecognitionPage";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/14-Pinyin/chapter-01/pinyin-o-tones.svg"),
      wordDetail: {
        toneContent: ["ō", "ó", "ǒ", "ò"],
        songUrlList: [
          require("@/assets/audio/L1/2-Pinyin-tones/o1.mp3"),
          require("@/assets/audio/L1/2-Pinyin-tones/o2.mp3"),
          require("@/assets/audio/L1/2-Pinyin-tones/o3.mp3"),
          require("@/assets/audio/L1/2-Pinyin-tones/o4.mp3"),
        ],
      },
    };
  },
  components: {
    RecognitionPage,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-three {
  width: 100%;
  height: 100%;
}
</style>