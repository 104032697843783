var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recognition-page-three" },
    [
      _c("RecognitionPage", {
        attrs: {
          backgroundImage: _vm.bgImg,
          wordObj: _vm.wordDetail,
          isTonePage: true,
          pySize: `xlarge`,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }