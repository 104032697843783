<!--
 * @Author: your name
 * @Date: 2022-03-16 10:06:35
 * @LastEditTime: 2022-03-22 19:10:51
 * @LastEditors: shmily
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter1\Lesson7\30_PinyinSummary.vue
-->
<template>
  <div class="rank-map-page-one">
    <PinyinSummary :pinyinImgList="pinyinImgList" :bgImg="bgImg" :lesson="7" />
  </div>
</template>

<script>
import PinyinSummary from "@/components/Course/CoursePage/PinyinSummary";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/12-Review/lesson-07-background.svg"),
    };
  },
  props: {
    lesson: {
      type: Number,
      require: false,
    },
  },
  computed: {
    pinyinImgList() {
      let imgArr = [];
      for (let i = 1; i <= 6; i++) {
        const element = require(`@/assets/img/12-Review/lesson-07-review-pinyin-${i}.svg`);
        imgArr.push(element);
      }
      return imgArr;
    },
  },
  components: {
    PinyinSummary,
  },
};
</script>

<style lang="scss" scoped>
.rank-map-page-one {
  width: 100%;
  height: 100%;
}
</style>
